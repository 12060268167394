.triggerButton {
  position: fixed;
  right: 40px;
  bottom: 5%;
  z-index: 4;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}

.modal {
  // position: fixed;
  // top: 30%;
  // right: 20px;
}

.formTitle {
  margin-bottom: 20px;
  text-align: center;
}

.formRowTextArea {
  width: 91%;
  margin-left: 38px;
}

.formItem {
  padding: 0 20px;
}

.MessageOutlined {
  font-size: 20px;
}

.draggerComp {
  width: 95%;
}
