.row {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  :global {
    .ant-collapse-item {
      border: 0;
    }
  }

  .preview {
    width: 100%;
  }
}
.leftBut {
  margin-bottom: 10px;
}
.centerBut {
  align-items: center;
  width: fit-content;
}
.rightBut {
  float: right;
  margin-bottom: 10px;
}

.searchBox {
  padding: 8px;

  .input {
    display: block;
    width: 200px;
    margin-bottom: 8px;
  }

  .button {
    width: 96px;
    margin-bottom: 0;
  }
}

// .scrollbar {
//   overflow-y: scroll;
// }

.force-overflow {
  min-height: 450px;
}
.divstyle {
  height: 150px;
  overflow-y: scroll;
}

.uploadBut {
  text-align: center;
  background-color: #ddd;
  // position: absolute;
  // top: 0;
  // right: 0;
  // min-width: 100%;
  // min-height: 100%;
  // font-size: 100px;
  // text-align: right;
  // filter: alpha(opacity=0);
  // opacity: 0;
  // outline: none;
  // background: white;
  // cursor: inherit;
  // display: block;
  border: 1px dashed #bbb;
  cursor: pointer;
}
