.sider {
  position: fixed;
  left: 0;
  z-index: 2;
  height: 100vh;
  overflow: auto;

  h1 {
    margin: 0;
    color: white;
    font-size: 30px;
  }

  h4 {
    margin: 0;
    color: orange;
  }

  .title {
    height: 64px;
    margin-bottom: 10px;
  }

  .logo {
    height: 50px;
    margin-top: 10px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .menuItem {
    margin: 10px 0;
    font-size: 15px;
  }
}

.main {
  min-height: 100vh;
  margin-left: 250px;

  .content {
    margin-top: 64px;
    padding: 15px;
  }

  .footer {
    padding: 0;
    background-color: white;
  }
}
