@import '../../styles/global.scss';

.menu {
  :global(.anticon) {
    margin-right: 8px;
  }

  :global(.ant-dropdown-menu-item) {
    min-width: 160px;
  }
}

.dropDown {
  line-height: $layout-header-height;
  vertical-align: top;
  cursor: pointer;

  > span {
    font-size: 16px !important;
    transform: none !important;

    svg {
      position: relative;
      top: -1px;
    }
  }
}
