.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.switchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}
.switchWrapper label {
  margin-right: 10px;
}
.viewCountWrapper {
  padding: 8px;
  border-left: 1px solid gainsboro;
}
.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.actionWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
