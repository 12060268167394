@import '../../styles/global.scss';

.sider {
  position: fixed;
  left: 0;
  z-index: 2;
  height: 100vh;
  overflow: auto;

  h1 {
    margin: 0;
    color: white;
    font-size: 20px;
  }

  h4 {
    margin: 0;
    color: $primary-color-2;
  }

  .title {
    height: 64px;
  }

  .logo {
    height: 40px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .spin {
    justify-content: center;
    height: calc(100vh - 64px);
    padding: 50% 0;
    text-align: center;
  }
}

.main {
  min-height: 100vh;
  margin-left: 250px;

  .content {
    margin-top: 64px;
    padding: 15px;
  }
}

.footer {
  padding: 0;
  background-color: white;
}
