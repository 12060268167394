@import '../../../styles/global.scss';

.main {
  width: 368px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  .verifyBut {
    display: block;
    width: 100%;
  }

  .password {
    margin-bottom: 24px;

    :global {
      .ant-form-item-explain {
        display: none;
      }
    }
  }

  .submit {
    width: 100%;
    margin-bottom: 0;
  }

  .floatleft {
    float: left;
    line-height: $btn-height-lg;
  }

  .floatright {
    float: right;
    line-height: $btn-height-lg;
  }
}
