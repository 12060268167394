.main {
  width: 368px;
  margin: 0 auto;

  h2 {
    text-align: center;
  }

  .submit {
    width: 100%;
  }

  .verifyBut {
    display: block;
    width: 100%;
  }
}
