.form {
  margin: 10px 30px;

  .input {
    width: 100%;
  }

  .icon {
    padding: 0 5px;
  }

  .collapsePanel {
    color: white;
    background-color: white;
  }
}
