$primary-color: #69c0ff;
$primary-color-2: #faad14;
$info-color: $primary-color;
$success-color: #52c41a;
$processing-color: #69c0ff;
$error-color: #ff4d4f;
$highlight-color: #ff4d4f;
$warning-color: #faad14;
$normal-color: #d9d9d9;
$white: #fff;
$black: #000;

$text-color-secondary: fade($black, 45%);
$heading-color: fade(#000, 85%);

$layout-body-background: #f0f2f5;
$layout-header-background: #212121;
$layout-header-height: 64px;

$btn-height-lg: 40px;
