@import '../../styles/global.scss';

$pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.header {
  position: fixed;
  z-index: 1;
  width: calc(100vw - 250px);
  padding-left: 15px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(97, 97, 97, 0.71);
}

.right {
  float: right;
}

.space {
  padding: 0 10px;
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s;

  > span {
    color: fade($black, 65%);
    vertical-align: middle;
  }

  &:hover {
    background: $pro-header-hover-bg;
  }

  &:global(.opened) {
    background: $pro-header-hover-bg;
  }
}

.avatar {
  margin: 0 5px;
  background-color: orange;
}
