.leftBut {
  margin-bottom: 10px;
}

.rightBut {
  float: right;
  margin-bottom: 10px;
}
.tabStyle {
  padding: 0 20px;
  background-color: #fff;
  // box-shadow: 0 10px 10px -10px rgb(0 0 0 / 10%);
}
.searchBox {
  padding: 8px;

  .input {
    display: block;
    width: 200px;
    margin-bottom: 8px;
  }

  .button {
    width: 96px;
    margin-bottom: 0;
  }
}
