/* .dashboard iframe {
  width: 100%;
  height: 900px;
} */

.analytic iframe {
  right: 0;
  bottom: 0;
  z-index: 999999;
  width: 100%;
  height: 900px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  border: none;
  /* sandbox: 'allow-popups' */
}

.dashboard {
  width: 600px;
  height: 600px;
  margin-top: 60px;
  background-color: #fff;
}
