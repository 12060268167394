@import '../../styles/global.scss';

.sider {
  position: fixed;
  left: 0;
  z-index: 2;
  height: 100vh;
  overflow: auto;

  .divider {
    margin: 10px 0;
    background-color: $primary-color-2;
  }

  h1 {
    margin: 0;
    color: white;
    font-size: 30px;
  }

  h4 {
    margin: 0;
    margin-bottom: 10px;
    color: $primary-color-2;
  }

  .title {
    height: 64px;
    margin-bottom: 10px;
  }

  .logo {
    height: 50px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .menu {
    padding-bottom: 40px;
  }

  .menuItem {
    margin: 10px 0;
    font-size: 15px;
  }

  .saveBut {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 250px;
    color: white;
    background-color: #ffa500;
  }

  .spin {
    justify-content: center;
    height: calc(100vh - 64px);
    padding: 50% 0;
    text-align: center;
  }
}

.main {
  min-height: 100vh;
  margin-left: 250px;

  .content {
    height: 100%;
    margin-top: 64px;
    padding: 15px;
    background-color: rgb(240, 242, 245);
    .loadingSpin {
      height: calc(100vh - 64px - 100px - 30px);
    }
  }

  .footer {
    height: 100px;
    padding: 0;
    background-color: white;
  }
}
