@import '../../styles/global.scss';

$pro-header-hover-bg: rgba(0, 0, 0, 0.025);

.header {
  position: fixed;
  z-index: 999;
  width: calc(100vw - 250px);
  padding-left: 15px;
  background-color: white;
  box-shadow: 0 0 10px 0 rgba(97, 97, 97, 0.71);
}
.right {
  float: right;
}

.item {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.3s;

  > span {
    color: fade($black, 65%);
    vertical-align: middle;
  }

  &:hover {
    background: $pro-header-hover-bg;
  }

  &:global(.opened) {
    background: $pro-header-hover-bg;
  }
}
.listitem {
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}
.listitemlatest {
  padding-left: 0%;
  font-weight: bold;
  background-color: rgb(242, 249, 255);
  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}
.left {
  display: flex;
  align-items: left;
  float: left;
  height: 100%;
  // padding: 0 20px;
  font-size: 1.2em;
  cursor: pointer;
  transition: all 0.3s;
}

.avatar {
  margin: 0 5px;
  background-color: orange;
}
.saveBut {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 150px;
  color: black;
  // background-color: #ffa500;
}
.liteBut {
  // position: fixed;
  // bottom: 0;
  // left: 0;
  width: 150px;
  margin: auto;
  color: black;
  // background-color: #ffa500;
}
